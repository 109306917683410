<template>
  <div id="app">
    <layout>
        <router-view/>
    </layout>
  </div>
</template>
<script>
import layout from '@/components/layout.vue';

export default {
   components: {
     layout
   }
}

</script>
<style lang="scss">
*{
  padding: 0;
  margin: 0;
}
#app{
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
  font-size: 1.6rem;
}
html{
  font-size: 10px;
  font-size: 0.52vw;
}
// @media screen and (max-width: 1560px) {
//   html{
//     font-size: 9px;
//   }
// }
// @media screen and (max-width: 1200px) {
//   html{
//     font-size: 8px;
//   }
// }
@media screen and (max-width: 992px) {
  html{
    font-size: 6px;
  }  
}
@media screen and (max-width: 768px) {
  html{
    font-size: 6px;
  }  
}
*::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 7px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 3px;
  display: none;
}
*::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 10px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background: #d8d8d8;
}
*::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  background: #f4f4f4;
}
</style>
